import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"set-password-page",attrs:{"fluid":"","fill-height":"","pa-0":""}},[_c(VRow,[_c(VCol,{staticClass:"set-password-form-wrapper text-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/svg/logo-short-colored.svg")}}),_c('p',{staticClass:"set-password-text"},[_vm._v(_vm._s(_vm.type)+" Password")]),_c(VDivider),_c('p',{staticClass:"description"},[(_vm.type ==='Set')?_c('span',[_vm._v("Thanks for confirming your email. ")]):_vm._e(),_vm._v(" Please "),_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(_vm.type)+" ")]),_vm._v(" your password. ")]),_c(VForm,{staticClass:"set-password-form",on:{"submit":_vm.submitForm}},[_c('BaseInput',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"solo":"","inputLabel":"Password","placeholder":"Password","name":"password","type":_vm.passwordFieldType.password,"status":_vm.errors('password').length ? 'error': 'normal',"error-messages":_vm.errors('password'),"appendIcon":(_vm.passwordFieldType.password === 'password') ? 'mdi-eye' : 'mdi-eye-off',"appendIconCallback":function () { return _vm.togglePasswordDisplay('password'); }},on:{"input":function($event){return _vm.$v.password.$touch()},"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('BaseInput',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"solo":"","inputLabel":"Confirm Password","placeholder":"Password","name":"confirmPassword","type":_vm.passwordFieldType.confirmPassword,"status":_vm.errors('confirmPassword').length ? 'error': 'normal',"error-messages":_vm.errors('confirmPassword'),"appendIcon":(_vm.passwordFieldType.confirmPassword === 'password') ?
           'mdi-eye' :
           'mdi-eye-off',"appendIconCallback":function () { return _vm.togglePasswordDisplay('confirmPassword'); }},on:{"input":function($event){return _vm.$v.confirmPassword.$touch()},"blur":function($event){return _vm.$v.confirmPassword.$touch()}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmPassword"}}),_c(VBtn,{staticClass:"set-password-button btn-purple",attrs:{"type":"submit","block":"","disabled":_vm.$v.$dirty && _vm.$v.$invalid}},[_vm._v(" "+_vm._s(_vm.type)+" Password ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }