<template>
  <v-container
    fluid
    fill-height
    pa-0
    class="set-password-page"
  >
    <v-row>
      <v-col cols=12 class="set-password-form-wrapper text-center">
        <img src="@/assets/svg/logo-short-colored.svg" class="logo" />
        <p class="set-password-text">{{type}} Password</p>
        <v-divider />
        <p class="description">
          <span v-if="type ==='Set'">Thanks for confirming your email. </span>
            Please
            <span class="text-lowercase">
            {{type}}
            </span> your password.
        </p>
        <v-form class="set-password-form" @submit="submitForm">
          <BaseInput
            solo
            inputLabel="Password"
            placeholder="Password"
            name="password"
            v-model.trim="password"
            :type="passwordFieldType.password"
            :status="errors('password').length ? 'error': 'normal'"
            :error-messages="errors('password')"
            :appendIcon="(passwordFieldType.password === 'password') ? 'mdi-eye' : 'mdi-eye-off'"
            :appendIconCallback="() => togglePasswordDisplay('password')"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            style="display: flex; flex-direction: column;"
          />
          <BaseInput
            solo
            inputLabel="Confirm Password"
            placeholder="Password"
            name="confirmPassword"
            v-model.trim="confirmPassword"
            :type="passwordFieldType.confirmPassword"
            :status="errors('confirmPassword').length ? 'error': 'normal'"
            :error-messages="errors('confirmPassword')"
            :appendIcon="(passwordFieldType.confirmPassword === 'password') ?
             'mdi-eye' :
             'mdi-eye-off'"
            :appendIconCallback="() => togglePasswordDisplay('confirmPassword')"
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
            style="display: flex; flex-direction: column;"
          />
          <v-btn
            class="set-password-button btn-purple"
            type="submit"
            block
            :disabled="$v.$dirty && $v.$invalid"
          >
            {{type}} Password
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';

const namespace = 'user';
export default {
  name: 'SetPassword',
  components: { BaseInput },
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordFieldType: {
        password: 'password',
        confirmPassword: 'password',
      },
    };
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'password':
            if (!this.$v.password.required) { errors.push('Please provide your password'); }
            break;
          case 'confirmPassword':
            if (!this.$v.confirmPassword.required) { errors.push('Please confirm your password'); }
            if (!this.$v.confirmPassword.sameAsPassword) { errors.push('Passwords do not match.'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    type() {
      const type = this.$route.params.confirmToken ? 'Set' : 'Reset';
      return type;
    },
  },
  methods: {
    ...mapActions(namespace, ['setPassword']),
    togglePasswordDisplay(type) {
      this.passwordFieldType[type] = this.passwordFieldType[type] === 'password' ? 'text' : 'password';
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { password, confirmPassword } = this;
        const { confirmToken, resetToken } = this.$route.params;
        const type = confirmToken ? 'set' : 'reset';
        const response = await this.setPassword({
          user: _.pickBy({
            password, confirmPassword, confirmToken, resetToken,
          }),
          type,
        });
        if (response.success) {
          this.$router.push('/login');
        }
      }
    },
  },
  validations: {
    password: {
      required,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>
<style lang="scss" scoped>
.set-password-page {
  height: 100%;
  background: $neutral6;
  flex-direction: column;
}
.set-password-form-wrapper {
  height: 100%;
}
.logo {
  margin: auto;
  margin-top: 62px;
  width: 80px;
}
.set-password-text {
  margin-top: 40px;
  font-size: 30px;
  font-weight: bold;
}
.description {
    font-size: 24px;
    margin-top: 15px;
}
.set-password-form {
  font-family: $fontFamily1;
  width: 300px;
  margin: auto;
  margin-top: 30px;
}
::v-deep .theme--light.v-label {
  color: $neutral1;
}
.set-password-button {
  margin-top: 20px;
  ::v-deep .v-btn__content {
    font-size: 16px;
  }
}
.v-btn.v-size--default {
  height: 48px !important;
}
.vlogin-form .v-btn__content {
  font-size: 16px;
}
::v-deep .v-input input::placeholder {
  font-size: 12px !important;
  color: $graySuite;
  letter-spacing: -0.12px;
}
@media (max-width: 600px) {
  .logo {
    margin-top: 47px;
  }
}
</style>
